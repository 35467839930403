<template>
<div v-if="errorText" class="error-container">
    <div class="form-error">{{ errorText }}</div>
</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        errorText: {
            type: String,
            required: false
        }
    }
})
</script>

<style scoped lang="scss">
.error-container {
    position: relative;
    max-width: 100%;
    width: 100%;

    .form-error {
        position: absolute;
        bottom: 8px;
        right: 0;
        background: var(--red-300);
        padding: 8px 12px;
        max-width: 66%;
        color: #000;
        font-size: 12px;
        font-weight: 500;
        line-height: 1.2em;
        text-align: left;
        border-radius: 2px;
        z-index: 3;
    }
}
</style>
